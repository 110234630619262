import React from 'react'
import headerMenuBar from '../Images/mb-menu-bar.png'
import playGamesMenuBar from '../Images/play-games.png'
import lightCar from '../Images/light-car.png'

export default function Footer() {
  return (
    <div className='footer'>
      <div className='footer--section'>
        <div className='pc'></div>
        <div className='mobile'>
          <div className='menu--section'>
            <a href='https://game.lsmlondon789.asia' className='go-to-homepage'></a>
            <a href='/PromotionPage' className='go-to-promotion'></a>
              <div className="playgame--menu--bar"><img src={playGamesMenuBar}  /></div>
          </div>
        </div>
      </div>
    </div>
  )
}
