import React, { useState, useEffect } from "react";
import Footer from '../Components/Footer';
import Header from '../Components/Header'
import Promotion from './Promotion';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Games from './Games';


import logoMb from '../Images/new-theme/body/mb/logo.webp'

import textCity from '../Images/new-theme/body/section-1-text.webp'
import city from '../Images/new-theme/body/section-1-city.webp'

import cityWhite from '../Images/new-theme/body/section-2-city-white.webp'
// import wheels from '../Images/new-theme/body/section-2-wheel2.webp'
import wheels from '../Images/new-theme/body/section-2-wheel3.webp'
import wheelsBase from '../Images/new-theme/body/section-2-wheel-base.webp'


import balloonBg from '../Images/new-theme/body/section-3-balloon.webp'

import bgSection4Top from '../Images/new-theme/body/section-4-top.webp'
import logoSection4 from '../Images/new-theme/body/section-4-logo.webp'
import bg2Section4 from '../Images/new-theme/body/section-4-bg.webp'
import bg3Section4 from '../Images/new-theme/body/section-4-games.webp'


import bgSection4Top2 from '../Images/new-theme/body/mb/mb-section-4-bg.webp'
import bgSection4TopPc2 from '../Images/new-theme/body/section-4-bg.webp'
import bgSection4Cloud from '../Images/new-theme/body/section-4-cloud.webp'
import bgSection4Moon from '../Images/new-theme/body/section-4-moon.webp'

import bgSection5 from '../Images/new-theme/body/section-5-bg.webp'
import step1 from '../Images/new-theme/body/section-5-step-1.webp'
import step2 from '../Images/new-theme/body/section-5-step-2.webp'


import bgSection7 from '../Images/new-theme/body/section-7-bg.webp'

import headerLogo from '../Images/new-theme/header/logo.webp'
import headerBtnRegister from '../Images/new-theme/header/btn-register.webp'
import headerBtnLogin from '../Images/new-theme/header/btn-login.webp'


import section9Logo from '../Images/new-theme/body/section-9-logo.webp'
import section9Flag from '../Images/new-theme/body/section-9-flag.webp'
import toTopBtn from '../Images/new-theme/body/section-9-to-top.webp'

// Mb
import mbCity from '../Images/new-theme/body/mb/mb-section-1-city.webp'
import mbBalloonBg from '../Images/new-theme/body/mb/mb-section-3-balloon.webp'
import mbBg2Section4 from '../Images/new-theme/body/mb/mb-section-4-bg.webp'
import mbBg3Section4 from '../Images/new-theme/body/mb/mb-section-4-games.webp'

import mbBgSection5 from '../Images/new-theme/body/mb/mb-section-5-bg.webp'

import mbBgSection7 from '../Images/new-theme/body/mb/mb-section-7-bg.webp'
import Providers from './Providers';
import Article from './Article';


export default function Home() {

  const [isVisible, setIsVisible] = useState(false);

  const onRegister = () => {
    window.location.replace('https://gamev2.lsmlondon789.com/register');
  }

  const onLogin = () => {
    window.location.replace('https://gamev2.lsmlondon789.com/login');
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // เลื่อนขึ้นอย่างนุ่มนวล
    });
  };


  useEffect(() => {
    let btnLogoMb = document.getElementById('btnLogoMb');
    let wheelSection = document.getElementById('wheel-section');
    let section2TextLeft = document.getElementById('section-2-text-left');

    let welcomeH2 = document.getElementById('welcomeH2');
    let welcomeH1 = document.getElementById('welcomeH1');
    let welcomeP = document.getElementById('welcomeP');


    let section4Cloud = document.getElementById('bg-section-4-cloud');
    let section4Moon = document.getElementById('bg-section-4-moon');
    
    let section5Step1 = document.getElementById('section5step1');
    let section5Step2 = document.getElementById('section5step2');

      const handleScroll = () => {
          let valueScrollY = window.scrollY;
          if (valueScrollY > 50) {

              setIsVisible(true);

              if(window.innerWidth > 767){

                if((valueScrollY - 700) < -100 ){
                  wheelSection.style.right = valueScrollY - 600+'px';
                  wheelSection.style.transitionDuration = '1s';
                }
  
                if((valueScrollY - 500) < 50 ){
                  section2TextLeft.style.left = valueScrollY - 500+'px';
                  section2TextLeft.style.transitionDuration = '1s';
                }


                // section4 cloud + moons
                const cloudMoonLeft = valueScrollY - 2500;
                if(cloudMoonLeft < 5){

                  section4Cloud.style.left = (cloudMoonLeft)+'px';
                  section4Cloud.style.transitionDuration = '1s';

                  section4Moon.style.right = (cloudMoonLeft)+'px';
                  section4Moon.style.transitionDuration = '1s';
                }



              }else{

                  // Section 1
                  // const btnLogoMbSections = valueScrollY + 160;
                  // const btnLogoMbSections2 = valueScrollY - btnLogoMbSections;
                  const btnLogoMbSections = valueScrollY - 100;

                  if(valueScrollY >= 100){
                    console.log("valueScrollY : "+btnLogoMbSections);
                    btnLogoMb.style.height = "auto";
                    btnLogoMb.style.transitionDuration = '2s';
                  }
                  else if(valueScrollY < 100){
                    btnLogoMb.style.height = "160px";
                    btnLogoMb.style.transitionDuration = '2s';
                  }

                  // Section2 วงล้อ และข้อความ
                  const wheelSections = valueScrollY - 1000;
                  const wheelSections2 = valueScrollY / 100;
                  if(wheelSections < -200){
                    wheelSection.style.right = wheelSections+'px';
                    wheelSection.style.bottom = wheelSections+'px';
                    wheelSection.style.width = '700px';
                    wheelSection.style.transitionDuration = '1s';
                  }
                  if(wheelSections2 < 1.5){
                    welcomeH2.style.top = -wheelSections2+'rem';
                    welcomeH2.style.transitionDuration = '.5s';

                    welcomeH1.style.top = -wheelSections2+'rem';
                    welcomeH1.style.transitionDuration = '1s';

                    welcomeP.style.top = -wheelSections2+'rem';
                    welcomeP.style.transitionDuration = '1.2s';
                  }

                  // section4 cloud + moons
                  const cloudMoonLeft = valueScrollY - 2520;
                  if(cloudMoonLeft < -220){

                    section4Cloud.style.left = (cloudMoonLeft)+'px';
                    section4Cloud.style.transitionDuration = '1s';

                    section4Moon.style.right = (cloudMoonLeft)+'px';
                    section4Moon.style.transitionDuration = '1s';
                  }


                  // section5 แนะนำเพื่อน
                  const step5ScrollY = (valueScrollY - 3500);
                  if(step5ScrollY <= 0){
                    section5Step1.style.left = step5ScrollY+'px';
                    section5Step1.style.transitionDuration = '1s';

                    section5Step2.style.right = step5ScrollY+'px';
                    section5Step2.style.transitionDuration = '1s';
                  }

              }

          } else {
              setIsVisible(false);
          }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className={`sticky-header ${isVisible ? "visible" : ""}`}>
        <Header />
      </div>
      <div className='home'>
        <div className='section-1'>
          <div className='text-city-bg'></div>
          <div className='city-bg'></div>
          
          <div className="btn-logo-section" id="btnLogoMb">
            <img src={logoMb} className='btn-logo-mb' alt='login' />
          </div>
        </div>

        <div className='section-2' id="about">
          <div className='mb'>
            <div className='btn-section'>
              <button className='btn btn-register' onClick={() => onRegister()}>
                <img src={headerBtnRegister} className='btn-register' alt='register' />
              </button>
              <button className='btn btn-login' onClick={() => onLogin()}>
                <img src={headerBtnLogin} className='btn-login' alt='login' />
              </button>
            </div>
          </div>

          <div className='text-left' id="section-2-text-left">
            <h2 id="welcomeH2">ยินดีต้อนรับสู่ LSM<span className='text-red'>LONDON</span>789</h2>
            <h1 id="welcomeH1">Welcome to <br />LSM<span className='text-red'>LONDON</span>789</h1>
            <p id="welcomeP">
              LSMLONDON789 คือเว็บพนันออนไลน์ที่ครบวงจรที่สุด ตอบโจทย์ทุกความต้องการของผู้เล่นทั้งหน้าใหม่และมืออาชีพ 
              ด้วยความหลากหลายของเกมที่เรานำเสนอ ไม่ว่าจะเป็นกีฬา คาสิโน สล็อต และเกมเดิมพันอื่น ๆ 
              ที่มาพร้อมกับระบบที่เสถียร ปลอดภัย และใช้งานง่าย ทุกการทำธุรกรรม ฝาก-ถอน เป็นระบบออโต้ รวดเร็วทันใจในไม่กี่วินาที นอกจากนี้ 
              เรายังมีโปรโมชั่นพิเศษที่คัดสรรมาเพื่อคุณโดยเฉพาะ พร้อมทีมงานมืออาชีพที่คอยให้บริการตลอด 24 ชั่วโมง 
              หากคุณกำลังมองหาเว็บเดิมพันที่มั่นคง เชื่อถือได้ และมอบความสนุกอย่างไม่มีที่สิ้นสุด LSMLONDON789 คือคำตอบของคุณ!
            </p>
          </div>

          <div className='wheel-section' id="wheel-section">
            <div className='wheel'>
              <img src={wheels} className='city-white-wheel' alt='wheel section2' />
            </div>
            <img src={wheelsBase} className='wheels-base' alt='wheels-base' />
          </div>
          <img src={cityWhite} className='city-white' alt='city white section2' />
        </div>

        <div className='section-3' id="promotion">

          <div className='detail'>
            <h4>โปรแรง แจกจริง คุ้มทุกการลงทุน!</h4>
            <h3>Meet Our <span className='text-red'>Exclusive Promotions</span></h3>
            <p>"เพิ่มความคุ้มค่าให้ทุกการเดิมพันกับโปรโมชั่นสุดพิเศษที่ LSMLondon789 คัดสรรมาเพื่อคุณโดยเฉพาะ 
              ไม่ว่าจะเป็นโบนัสแรกเข้า โบนัสเติมเงิน หรือคืนยอดเสีย ทุกโปรโมชั่นออกแบบมาเพื่อให้คุณได้รับสิทธิประโยชน์สูงสุด 
              พร้อมให้คุณสนุกไปกับเกมโปรดและรับรางวัลมากมายได้ทุกวัน!"</p>
          </div>

          <Promotion />
        </div>

        <div className="section-4-1" id="games">
          <div className="mb">
            <img src={bgSection4Top2} className='bg-section-4-top-s' alt='top' />
          </div>
          <div className="pc">
            <img src={bgSection4TopPc2} className='bg-section-4-top-s' alt='top' />
          </div>

          <div className="cloud-section">
            <div className="bg-section-4-cloud" id="bg-section-4-cloud"></div>
            <div className="bg-section-4-moon" id="bg-section-4-moon"></div>
          </div>

          <div className="box-bg-games-bg">
            <div className='detail'>
                <h4>เกมส์จัดเต็ม ครอบคลุมทั้งกีฬา คาสิโน สล็อต และอีกมากมาย</h4>
                <h3>Packed with <span className='text-red'>Sports, Casino, Slots, and More</span></h3>
                <p>"สนุกไปกับเกมหลากหลายที่ LSMLondon789 ภูมิใจนำเสนอ ครอบคลุมทั้งกีฬา คาสิโน สล็อต และเกมยอดฮิตอื่น ๆ 
                  ที่คัดสรรมาอย่างดี พร้อมให้คุณเลือกเล่นได้ไม่มีเบื่อ ทุกเกมออกแบบมาเพื่อตอบสนองทุกไลฟ์สไตล์และความชอบ 
                  ให้คุณเพลิดเพลินไปกับความบันเทิงเต็มรูปแบบในที่เดียว!"</p>
              </div>


              <div className='game-section'>
                <Games />
                <span className='pc'><img src={bg3Section4} className='bg-games-round-border' alt='games'/></span>
                <span className='mb'><img src={mbBg3Section4} className='bg-games-round-border' alt='games'/></span>
              </div>
          </div>
        </div>



        <div className='section-5'>
          <span className='pc'><img src={bgSection5} className='bg-flag-bottom' alt='flag-bottom'/></span>
          <span className='mb'><img src={mbBgSection5} className='bg-flag-bottom' alt='flag-bottom'/></span>


          <div className='detail'>
            <h4>ชวนเพื่อนสนุก รับโบนัสจุก ยิ่งชวนมาก ยิ่งได้มาก!</h4>
            <h3>more refer, <span className='text-red'>more gain!</span></h3>
            <p>"เพิ่มความสนุกและสร้างรายได้ไปพร้อม ๆ กันด้วยระบบแนะนำเพื่อนของเรา 
              เพียงแค่แชร์ลิงก์สมัครให้เพื่อนของคุณ เมื่อเพื่อนสมัครและเริ่มเล่น คุณจะได้รับค่าคอมมิชชันทันที 
              ยิ่งชวนมาก ยิ่งได้มาก และไม่ใช่แค่คุณเท่านั้นที่ได้รับ เพื่อนของคุณก็จะได้สิทธิพิเศษจากโปรโมชั่นสุดคุ้มอีกด้วย 
              ชวนเลยวันนี้ สนุก คุ้ม และมีรายได้เพิ่มแบบง่าย ๆ!"</p>
          </div>

          <div className='steps'>
            <img src={step1} className='step1' id="section5step1" alt='step1'/>
            <img src={step2} className='step2' id="section5step2" alt='step2'/>
          </div>
        </div>


        <div className='section-6'>
          <div className='title-section6'>
            <span className='pc'>
              <h4>Partnered with leading game providers from <span className='text-red'>around the world.</span></h4>
            </span>
            <span className='mb'>
              <h4>Partnered with leading game...</h4>
            </span>
          </div>

          <Providers />
        </div>


        <div className='section-7' id="articles">

          <div className='detail'>
            <h4>ข่าวสารความรู้ และ บทความ</h4>
            <h3>Knowledges & <span className='text-red'>Articles</span></h3>

            <div className='btn-section'>
              <button type='button' className='btn latest' onClick={() => onRegister()}>ล่าสุด</button>
              <button type='button' className='btn' onClick={() => onRegister()}>คาสิโน</button>
              <button type='button' className='btn' onClick={() => onRegister()}>กีฬา</button>
              <button type='button' className='btn' onClick={() => onRegister()}>สล็อต</button>
              <button type='button' className='btn' onClick={() => onRegister()}>มวย</button>
              <button type='button' className='btn' onClick={() => onRegister()}>หวย</button>
            </div>
          </div>

          <div className='article-section'>
            <h4>บทความและข่าวสารล่าสุด &nbsp; <a href="#">ดูทั้งหมด</a></h4>

            <Article />
          </div>
        </div>

        <div className='section-8' id="contact">
          <div className='left'>
            <img src={headerLogo} className='logo-bottom' />
            <h3>สมัครสมาชิกได้ตลอด 24 ชั่วโมง</h3>
          </div>
          <div className='right'>
            <button className='btn btn-register' onClick={() => onRegister()}>
              <img src={headerBtnRegister} className='btn-register' alt='register' />
            </button>
            <button className='btn btn-login' onClick={() => onLogin()}>
              <img src={headerBtnLogin} className='btn-login' alt='login' />
            </button>
          </div>
        </div>


        <div className='section-9'>
          <div className='bottom-logo-box'>
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
            <img src={section9Logo} alt='logo' className='bottom-logo' />
          </div>

          <div className='bottom-menu'>
            <a href='#' title='เกี่ยวกับเรา' className='menu'>เกี่ยวกับเรา</a>
            <a href='#' title='โปรโมชั่น' className='menu'>โปรโมชั่น</a>
            <a href='#' title='ผลิตภัณฑ์' className='menu'>ผลิตภัณฑ์</a>
            <a href='#' title='แนะนำเพื่อน' className='menu'>แนะนำเพื่อน</a>
            <a href='#' title='บทความ' className='menu'>บทความ</a>
            <a href='#' title='ติดต่อเรา' className='menu'>ติดต่อเรา</a>
          </div>
          <img src={section9Flag} alt='bottom-flag' className='bottom-flag' />
          <img src={toTopBtn} alt='btn-to-top' className='btn-to-top' onClick={scrollToTop}/>
        </div>



      </div>{/* End home */}

    </>
  )
}
