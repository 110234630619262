import React from 'react'

import ab from '../Images/games/ab2.png'
import advantplay from '../Images/games/advantplay.png'
import ae from '../Images/games/ae.png'
import amb from '../Images/games/amb.png'
import ambslot from '../Images/games/ambslot.png'
import bg from '../Images/games/bg.png'
import blackants from '../Images/games/blackants.png'
import bng from '../Images/games/bng.png'
import btgaming from '../Images/games/btgaming.png'
import cherry from '../Images/games/cherry.png'
import dg from '../Images/games/dg.png'
import ebet from '../Images/games/ebet.png'
import epicwin from '../Images/games/epicwin.png'
import evolution from '../Images/games/evolution.png'
import evoplay from '../Images/games/evoplay.png'
import expanse from '../Images/games/expanse.png'
import fastspin from '../Images/games/fastspin.png'
import fg from '../Images/games/fg.png'
import galaxys from '../Images/games/galaxys.png'
import gpi from '../Images/games/gpi.png'
import gw from '../Images/games/gw.png'
import haba from '../Images/games/haba.png'
import hg from '../Images/games/hg.png'
import jili from '../Images/games/jili.png'
import jk from '../Images/games/jk.png'
import ka from '../Images/games/ka.png'
import kingmaker from '../Images/games/kingmaker.png'
import live22 from '../Images/games/live22.png'
import lotto from '../Images/games/lotto.png'
import lottoexit from '../Images/games/lottoexit.png'
import lsm99lotto from '../Images/games/lsm99lotto.png'
import megawin from '../Images/games/megawin.png'
import mg from '../Images/games/mg.png'
import mp from '../Images/games/mp.png'
import mglive from '../Images/games/mglive.png'
import muaypukyok from '../Images/games/muaypukyok.png'
import mylotto99 from '../Images/games/mylotto99.png'
import naga from '../Images/games/naga.png'
import newsportbook from '../Images/games/newsportbook.png'
import pg from '../Images/games/pg.png'
import nextspin from '../Images/games/nextspin.png'
import pinnacle from '../Images/games/pinnacle.png'
import playtech from '../Images/games/playtech.png'
import ponymuah from '../Images/games/ponymuah.png'
import pp from '../Images/games/pp.png'
import pretty from '../Images/games/pretty.png'
import ps from '../Images/games/ps.png'
import qqkeno from '../Images/games/qqkeno.png'
import relax from '../Images/games/relax.png'
import rich from '../Images/games/rich.png'
import sa from '../Images/games/sa.png'
import sabasport from '../Images/games/sabasport.png'
import sbo from '../Images/games/sbo.png'
import sexy from '../Images/games/sexy.png'
import spinx from '../Images/games/spinx.png'
import spsg from '../Images/games/spsg.png'
import sw from '../Images/games/sw.png'
import threesing from '../Images/games/threesing.png'
import ts from '../Images/games/ts.png'
import uuslot from '../Images/games/uuslot.png'

import wcasino from '../Images/games/wcasino.png'
import we from '../Images/games/we.png'
import wgb from '../Images/games/wgb.png'
import wm from '../Images/games/wm.png'
import yeebet from '../Images/games/yeebet.png'

export default function Games() {
  return (
    <>
        <div className='games'>
            <div className='games-1'>
                <img src={ab} alt='ab'/>
                <img src={advantplay} alt='advantplay'/>
                <img src={ae} alt='ae'/>
                <img src={amb} alt='amb'/>
                <img src={ambslot} alt='ambslot'/>
                <img src={bg} alt='bg'/>
                <img src={blackants} alt='blackants'/>
                <img src={bng} alt='bng'/>

                <img src={btgaming} alt='btgaming'/>
                <img src={cherry} alt='cherry'/>
                <img src={dg} alt='dg'/>
                <img src={ebet} alt='ebet'/>
                <img src={epicwin} alt='epicwin'/>
                <img src={evolution} alt='evolution'/>
                <img src={evoplay} alt='evoplay'/>
                <img src={expanse} alt='expanse'/>
                <img src={fastspin} alt='fastspin'/>
                <img src={fg} alt='fg'/>
                <img src={galaxys} alt='galaxys'/>
                <img src={gpi} alt='gpi'/>

                <img src={ab} alt='ab'/>
                <img src={advantplay} alt='advantplay'/>
                <img src={ae} alt='ae'/>
                <img src={amb} alt='amb'/>
                <img src={ambslot} alt='ambslot'/>
                <img src={bg} alt='bg'/>
                <img src={blackants} alt='blackants'/>
                <img src={bng} alt='bng'/>

            </div>
            <div className='games-2'>
                <img src={gw} alt='gw'/>
                <img src={haba} alt='haba'/>
                <img src={hg} alt='hg'/>
                <img src={jili} alt='jili'/>
                <img src={jk} alt='jk'/>
                <img src={ka} alt='ka'/>
                <img src={kingmaker} alt='kingmaker'/>
                <img src={live22} alt='live22'/>

                <img src={megawin} alt='megawin'/>
                <img src={mg} alt='mg'/>
                <img src={mp} alt='mp'/>
                <img src={mglive} alt='mglive'/>
                <img src={muaypukyok} alt='muaypukyok'/>
                <img src={mylotto99} alt='mylotto99'/>
                <img src={naga} alt='naga'/>
                <img src={newsportbook} alt='newsportbook'/>
                <img src={pg} alt='pg'/>
                <img src={relax} alt='relax'/>
                <img src={rich} alt='rich'/>
                <img src={sa} alt='sa'/>
                <img src={sabasport} alt='sabasport'/>
                
                <img src={gw} alt='gw'/>
                <img src={haba} alt='haba'/>
                <img src={hg} alt='hg'/>
                <img src={jili} alt='jili'/>
                <img src={jk} alt='jk'/>
                <img src={ka} alt='ka'/>
                <img src={kingmaker} alt='kingmaker'/>
                <img src={live22} alt='live22'/>
            </div>
            <div className='games-3'>
                <img src={nextspin} alt='nextspin'/>
                <img src={pinnacle} alt='pinnacle'/>
                <img src={playtech} alt='playtech'/>
                <img src={ponymuah} alt='ponymuah'/>
                <img src={pp} alt='pp'/>
                <img src={pretty} alt='pretty'/>
                <img src={ps} alt='ps'/>
                <img src={qqkeno} alt='qqkeno'/>

                <img src={sbo} alt='sbo'/>
                <img src={sexy} alt='sexy'/>
                <img src={spinx} alt='spinx'/>
                <img src={spsg} alt='spsg'/>
                <img src={sw} alt='sw'/>
                <img src={threesing} alt='threesing'/>
                <img src={ts} alt='ts'/>
                <img src={uuslot} alt='uuslot'/>
                
                <img src={wcasino} alt='wcasino'/>
                <img src={we} alt='we'/>
                <img src={wgb} alt='wgb'/>
                <img src={wm} alt='wm'/>
                <img src={yeebet} alt='yeebet'/>
                
                <img src={nextspin} alt='nextspin'/>
                <img src={pinnacle} alt='pinnacle'/>
                <img src={playtech} alt='playtech'/>
                <img src={ponymuah} alt='ponymuah'/>
                <img src={pp} alt='pp'/>
                <img src={pretty} alt='pretty'/>
                <img src={ps} alt='ps'/>
                <img src={qqkeno} alt='qqkeno'/>
            </div>
        </div>
    </>
  )
}

