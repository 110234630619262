import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import {Container, Row, Col, Tab, Tabs} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import headerBg from '../Images/new-theme/header/bg-header.webp'
import headerLogo from '../Images/new-theme/header/logo.webp'
import headerBtnRegister from '../Images/new-theme/header/btn-register.webp'
import headerBtnLogin from '../Images/new-theme/header/btn-login.webp'

export default function Header() {

  const [isVisible, setIsVisible] = useState(false);

  const onRegister = () => {
    window.location.replace('https://gamev2.lsmlondon789.com/register');
  }

  const onLogin = () => {
    window.location.replace('https://gamev2.lsmlondon789.com/login');
  }

  useEffect(() => {
      const handleScroll = () => {
          if (window.scrollY > 50) {
              setIsVisible(true);
          } else {
              setIsVisible(false);
          }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
    <div className={`header`}>
        <div className='logo'><img src={headerLogo} alt='london789' /></div>
            <div className='center'>
                <a href='#about'>เกี่ยวกับเรา</a>
                <a href='#promotion'>โปรโมชั่น</a>
                <a href='#games'>ผลิตภัณฑ์</a>
                <a href='#articles'>บทความ</a>
                <a href='#contact'>ติดต่อเรา</a>
            </div>
        <span className='pc'>
            <div className='right'>
                <a href='#register' className='btn btn-register' onClick={() => onRegister()}><img src={headerBtnRegister} alt='สมัครสมาชิก' /></a>
                <a href='#login' className='btn btn-login' onClick={() => onLogin()}><img src={headerBtnLogin} alt='เข้าสู่ระบบ' /></a>
            </div>
        </span>
    </div>

    </>
  )
}
