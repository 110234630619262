import logo from '../src/Images/favico.png';
// import './App.css';
import './Style/theme.scss'
import RoutePages from './Components/RoutePages';
import Header from './Components/Header';
import Footer from './Components/Footer';

function App() {
  return (
    <>
    <div className='wrapper wrapper--bg'>
        <RoutePages />
    </div>
    </>
  );
}

export default App;
