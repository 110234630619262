import React from 'react'
import { useEffect, useState } from 'react';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, EffectFlip, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import article1 from '../Images/new-theme/body/section-7-article1.webp'
import article2 from '../Images/new-theme/body/section-7-article2.webp'
import article3 from '../Images/new-theme/body/section-7-article3.webp'

export default function Article() {

  const [slidesPerView, setSlidesPerView] = useState(3);
    useEffect(() => {

      if (window.innerWidth < 767) {
        if(window.innerWidth > 480)
          setSlidesPerView(2)
        else
          setSlidesPerView(1)
        
      }

    }, []);
  return (
    <>
    <div className='articles'>

    <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={8}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className='article'>
                <div className='img'>
                    <img src={article1} className='article-img' alt='article-img' />
                </div>
                <div className='date'>
                    <div className='badge'>คาสิโน</div>
                    <div>
                    <strong>เผยแพร่ : </strong>
                    <span>09/02/2025</span>
                    </div>
                </div>
                <div className='detail-article'>
                    <h5>คาสิโนถูกกฎหมาย เสี่ยงฟอกเงิน-ทุนเทา? เลือกใช้ให้เหมาะสมกับไทย</h5>
                    <p>
                    สภาเห็นชอบรายงาน “คาสิโนถูกฎหมาย” ชี้ช่วยเศรษฐกิจ ลดพนันใต้ดิน หลายคนกังวล...
                    </p>
                    <a href='#'>อ่านเพิ่มเติม</a>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='article'>
                <div className='img'>
                    <img src={article2} className='article-img' alt='article-img' />
                </div>
                <div className='date'>
                <div className='badge'>คาสิโน</div>
                <div>
                    <strong>เผยแพร่ : </strong>
                    <span>09/02/2025</span>
                </div>
                </div>
                <div className='detail-article'>
                    <h5>คาสิโนถูกกฎหมาย เสี่ยงฟอกเงิน-ทุนเทา? เลือกใช้ให้เหมาะสมกับไทย</h5>
                    <p>
                        สภาเห็นชอบรายงาน “คาสิโนถูกฎหมาย” ชี้ช่วยเศรษฐกิจ ลดพนันใต้ดิน หลายคนกังวล...
                    </p>
                    <a href='#'>อ่านเพิ่มเติม</a>
                </div>
            </div>

        </SwiperSlide>
        <SwiperSlide>
            <div className='article'>
                <div className='img'>
                  <img src={article3} className='article-img' alt='article-img' />
                </div>
                <div className='date'>
                  <div className='badge'>คาสิโน</div>
                  <div>
                    <strong>เผยแพร่ : </strong>
                    <span>09/02/2025</span>
                  </div>
                </div>
                <div className='detail-article'>
                  <h5>คาสิโนถูกกฎหมาย เสี่ยงฟอกเงิน-ทุนเทา? เลือกใช้ให้เหมาะสมกับไทย</h5>
                  <p>
                    สภาเห็นชอบรายงาน “คาสิโนถูกฎหมาย” ชี้ช่วยเศรษฐกิจ ลดพนันใต้ดิน หลายคนกังวล...
                  </p>
                  <a href='#'>อ่านเพิ่มเติม</a>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='article'>
                <div className='img'>
                  <img src={article3} className='article-img' alt='article-img' />
                </div>
                <div className='date'>
                  <div className='badge'>คาสิโน</div>
                  <div>
                    <strong>เผยแพร่ : </strong>
                    <span>09/02/2025</span>
                  </div>
                </div>
                <div className='detail-article'>
                  <h5>คาสิโนถูกกฎหมาย เสี่ยงฟอกเงิน-ทุนเทา? เลือกใช้ให้เหมาะสมกับไทย</h5>
                  <p>
                    สภาเห็นชอบรายงาน “คาสิโนถูกฎหมาย” ชี้ช่วยเศรษฐกิจ ลดพนันใต้ดิน หลายคนกังวล...
                  </p>
                  <a href='#'>อ่านเพิ่มเติม</a>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>


            </div>
    </>
  )
}
