import React from 'react'

import brand1 from '../Images/new-theme/body/section-6-brand-1.webp'
import brand2 from '../Images/new-theme/body/section-6-brand-2.webp'
import brand3 from '../Images/new-theme/body/section-6-brand-3.webp'
import brand4 from '../Images/new-theme/body/section-6-brand-4.webp'
import brand5 from '../Images/new-theme/body/section-6-brand-5.webp'
import brand6 from '../Images/new-theme/body/section-6-brand-6.webp'
import brand7 from '../Images/new-theme/body/section-6-brand-7.webp'
import brand8 from '../Images/new-theme/body/section-6-brand-8.webp'
import brand9 from '../Images/new-theme/body/section-6-brand-9.webp'
import brand10 from '../Images/new-theme/body/section-6-brand-10.webp'
import brand11 from '../Images/new-theme/body/section-6-brand-11.webp'

export default function Providers() {
  return (
    <>
    
    <div className='brands'>
            <div className='top'>
              <img src={brand1} alt='brand' className='brand' />
              <img src={brand2} alt='brand' className='brand' />
              <img src={brand3} alt='brand' className='brand' />
              <img src={brand4} alt='brand' className='brand' />
              <img src={brand5} alt='brand' className='brand' />

              <img src={brand1} alt='brand' className='brand' />
              <img src={brand2} alt='brand' className='brand' />
              <img src={brand3} alt='brand' className='brand' />
              <img src={brand4} alt='brand' className='brand' />
              <img src={brand5} alt='brand' className='brand' />

              <img src={brand1} alt='brand' className='brand' />
              <img src={brand2} alt='brand' className='brand' />
              <img src={brand3} alt='brand' className='brand' />
              <img src={brand4} alt='brand' className='brand' />
              <img src={brand5} alt='brand' className='brand' />

              <img src={brand1} alt='brand' className='brand' />
              <img src={brand2} alt='brand' className='brand' />
              <img src={brand3} alt='brand' className='brand' />
              <img src={brand4} alt='brand' className='brand' />
              <img src={brand5} alt='brand' className='brand' />

              <img src={brand1} alt='brand' className='brand' />
              <img src={brand2} alt='brand' className='brand' />
              <img src={brand3} alt='brand' className='brand' />
              <img src={brand4} alt='brand' className='brand' />
              <img src={brand5} alt='brand' className='brand' />

              <img src={brand1} alt='brand' className='brand' />
              <img src={brand2} alt='brand' className='brand' />
              <img src={brand3} alt='brand' className='brand' />
              <img src={brand4} alt='brand' className='brand' />
              <img src={brand5} alt='brand' className='brand' />
            </div>
            <div className='bottom'>
              {/* <img src={brand6} alt='brand' className='brand' /> */}
              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />

              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />

              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />

              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />

              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />

              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />

              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />

              <img src={brand7} alt='brand' className='brand' />
              <img src={brand8} alt='brand' className='brand' />
              <img src={brand9} alt='brand' className='brand' />
              <img src={brand10} alt='brand' className='brand' />
              {/* <img src={brand11} alt='brand' className='brand' /> */}
            </div>
          </div>
    </>
  )
}
