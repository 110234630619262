import React from 'react';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style/Promotion.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, EffectFlip, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import promotion1 from '../Images/new-theme/body/promotion-1.webp'
import promotion2 from '../Images/new-theme/body/promotion-2.webp'
import promotion3 from '../Images/new-theme/body/promotion-3.webp'
import promotion4 from '../Images/new-theme/body/promotion-4.webp'
import promotion5 from '../Images/new-theme/body/promotion-5.webp'
import btnIndicatorPrev from '../Images/new-theme/body/section-3-prev.webp'
import btnIndicatorNext from '../Images/new-theme/body/section-3-next.webp'


export default function Promotion() {
    const images = [
        promotion1,
        promotion2,
        promotion3,
        promotion4,
        promotion5,
      ];

    return (
        <>
            <div className='promotion'>
                <Swiper
                    effect="coverflow"
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView="auto"
                    loop={true}
                    autoplay={{
                    delay: 3000, // Auto-slide every 2 seconds
                    disableOnInteraction: false, // Continue autoplay even after interaction
                    }}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 80,
                        depth: 200,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    modules={[ Autoplay, Pagination, Navigation, EffectFlip]}
                    // navigation={true}
                    navigation={{
                        nextEl: '.swiper-button-next', // Custom next button
                        prevEl: '.swiper-button-prev', // Custom prev button
                      }}
                      pagination={{ clickable: true }}
                    // 
                >
                    {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image} alt={`Coverflow ${index}`} />
                    </SwiperSlide>
                    ))}
                </Swiper>

                <div className='indicator'>
                    <div className='pc'>
                        <div className='prev '><img src={btnIndicatorPrev} alt='prev' className='btn-prev swiper-button-prev' /></div>
                        <div className='next '><img src={btnIndicatorNext} alt='next' className='btn-next swiper-button-next' /></div>
                    </div>
                </div>









                {/* <div className='img-slide'>
                    <img src={promotion1} className='promotion-img' alt='promotion1'/>
                </div>

                <div className='indicator'>
                    <div className='pc'>
                        <a href='#' className='prev'><img src={btnIndicatorPrev} alt='prev' className='btn-prev' /></a>
                        <a href='#' className='next'><img src={btnIndicatorNext} alt='next' className='btn-next' /></a>
                    </div>
                </div> */}

            </div>
        </>
    )
}
